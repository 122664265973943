.popup_disput {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  flex-flow: column;

  transform: translate(-50%, -50%);
}
