.popup__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  width: 100vw;
  height: 100vh;

  background: rgba(42, 44, 47, .7);
}
