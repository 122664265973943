.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .popup {
  display: none;
  width: 587px;
  position: relative;
  margin: 0 auto;
  border-radius: 4px;
}
.popup p {
  margin-bottom: 10px;
}
.popup__title {
  font-size: 20px;
  font-weight: 400;
  padding: 0 30px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  line-height: 1;
  display: -ms-flexbox;
  display: flex;
}

.popup__title span.active {
  display: inline-block;
  height: 100%;
  border-bottom-color: #1fb579;
  padding-left: 18px;
  padding-right: 18px;
}
.popup__title span {
  padding: 16px 0 20px;
}
.popup__title a {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding: 16px 18px 20px;
}
.popup__title a, .popup__title span {
  border-bottom: 2px solid transparent;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  color: #1fb579;
  text-decoration: none;
  transition: border-color .35s,color .35s;
}

.popup--sm {
  width: 360px;
  padding: 0px !important;
}

.popup-form .fancybox-content {
  cursor: inherit!important;
}
.popup-frm .popup__inner>:last-child {
  margin-bottom: 0;
}
.popup-frm input {
  line-height: normal;
}
.popup__inner {
  padding: 20px 30px 30px;
  background-color: #f9f7f2;
  color: #444;
}
.centered, .steps-item__num {
  text-align: center;
}
.social-auth {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
}
.social-auth__item--gp {
  background-color: #d93d25;
}
.social-auth__item--fb {
  background-color: #3b569d;
}
.social-auth__item--vk {
  background-color: #427da6;
}
.social-auth__item--ok {
  background-color: #ff8d00;
}
.social-auth__item--mr {
  background-color: #134785;
}
.social-auth__item:last-child {
  margin-right: 0;
}
.social-auth__item {
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0;
  border-radius: 4px;
  margin-right: 9px;
  transition: opacity .3s;
  height: 49px;
  width: 49px;
}

.info-msg {
  background-color: #1fb579;
}

.error-msg, .error-msg a, .info-msg {
  color: #fff;
}
.error-msg, .info-msg {
  font-size: 15px;
  line-height: 1.45em;
  padding: .5em 1em .65em;
  border-radius: 4px;
  margin-bottom: 10px;
}
.input-holder {
  position: relative;
  margin-bottom: 10px;
  line-height: 0;
}
.password-holder {
  position: relative;
  margin-bottom: 10px;
}
.password-holder input {
  margin-bottom: 0!important;
}
.password-holder a {
  position: absolute;
  right: 18px;
  height: 100%;
  top: 0;
  line-height: 48px;
  font-size: 14px;
}
.popup-frm [type=color], .popup-frm [type=date], .popup-frm [type=datetime], .popup-frm [type=datetime-local], .popup-frm [type=email], .popup-frm [type=month], .popup-frm [type=password], .popup-frm [type=tel], .popup-frm [type=text], .popup-frm [type=time], .popup-frm [type=url], .popup-frm [type=week], .popup-frm [type=number], .popup-frm [type=search], .popup-frm input:not([type]), .popup-frm textarea {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
}

.input-holder input {
  margin-bottom: 0!important;
}
.popup-frm input {
  line-height: normal;
}
.input-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 14px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;}
  .input-row>* {
    margin-right: 12px;
}

.radio-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}
input[type=checkbox]:not(old), input[type=radio]:not(old) {
  width: 2em;
  margin: 0;
  padding: 0;
  font-size: 0;
  opacity: 0;
  display: none;
}
input[type=checkbox]:not(old)+label, input[type=radio]:not(old)+label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1.3em;
  font-size: 16px;
}


[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=password], [type=tel], [type=text], [type=time], [type=url], [type=week], [type=number], [type=search], input:not([type]), textarea {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 1rem;
  font-weight: 300;
  height: 50px;
  line-height: 48px;
  padding: 0 20px .15em;
  box-shadow: 0 3px 4px rgba(218,218,218,.35);
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  text-overflow: ellipsis;
  transition: border-color .35s,box-shadow .35s;
}

.fancybox-container * {
  box-sizing: border-box;
}
.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container, .fancybox-thumbs, .fancybox-thumbs>ul>li, .noUi-target, .noUi-target * {
  -webkit-tap-highlight-color: transparent;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px;
  max-width: 100%;
  overflow: auto;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}
.fancybox-slide--html {
  padding: 6px 6px 0;
}
.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px;
}
.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translate3d(0,0,0);
  z-index: 99994;
}
.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fancybox-is-open .fancybox-bg {
  opacity: .66;
}

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(.22,.61,.36,1);
}
.fancybox-bg {
  background: #000;
}
.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.47,0,.74,.71);
}
.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}
.fancybox-close-small {
  border: 0;
  border-radius: 0;
  color: transparent;
  cursor: pointer;
  margin: 0;
  position: absolute;
  right: 8px;
  top: 13px;
  height: 30px;
  width: 30px;
  z-index: 10;
}
.fancybox-close-small, .fancybox-content {
  background: 0 0;
  padding: 0;
}
.fancybox-close-small .icon {
  height: 16px;
  width: 16px;
  fill: #d3d3d3;
  transition: fill .35s;
}
.fancybox-close-small svg {
  fill: #fff;
  opacity: 1;
  stroke: none;
  stroke-width: 1.5;
}
.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}
.pagination--sm, .popup-frm__submit {
  margin-top: 20px;
}

.popup__wide-btn {
  width: 100%;
  height: 62px;
  line-height: 60px;
  border-radius: 0;
  margin-top: 0!important;
}

.btn--1, .btn--2, .btn--4-1, .btn--4-2 {
  min-width: 212px;
  height: 52px;
  padding: 0 30px;
  line-height: 52px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  background-color: #1fb579;
  text-transform: uppercase;
  border-radius: 4px;
  transition: background-color .3s;
}
.active-rating__item, .all-btn, .btn--1, .btn--2, .btn--3, .btn--3-1, .btn--4-1, .btn--4-2, .delete-btn, .delete-btn-2, .edit-btn, .menu-btn, .play, .remove, .search-btn, .search-show-btn, .show-hidden-params, .show-sidebar-btn {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
  background: 0 0;
  outline: 0;
  padding: 0;
}
.btn--2 {
  background-color: #f89020;
}
.error-msg {
  background-color: #ff4d40;
}
.double-input-vertical>:first-child {
  margin-bottom: 1px;
  border-radius: 4px 4px 0 0;
}
input[type=checkbox]:not(old):checked+label>span {
  border-color: #1fb579;
  background-color: #1fb579;
  box-shadow: none;
}

input[type=checkbox]:not(old)+label>span {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 24px;
  width: 24px;
  box-shadow: 0 3px 4px rgba(218,218,218,.35);
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  margin: 0 8px 0 0;
  vertical-align: bottom;
}
input[type=checkbox]:not(old):checked+label>span:before, input[type=radio]:not(old):checked+label>span:before {
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

input[type=checkbox]:not(old)+label>span:before {
  content: "";
  display: inline-block;
  margin: auto;
  height: 11px;
  width: 14px;
  background-repeat: no-repeat;
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  transition: transform .35s,opacity .35s;
}
input[type=radio]:not(old):checked+label>span {
  background-color: #1fb579;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
input[type=radio]:not(old)+label>span {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #1fb579;
  background-color: #fff;
  margin: 0 8px 0 0;
  border-radius: 50%;
  vertical-align: bottom;
}
input[type=radio]:not(old):checked+label>span>span {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  margin: auto;
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
input[type=radio]:not(old)+label>span>span {
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  transition: transform .35s,opacity .35s;
}