.messages-chat__separator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
}

.messages-chat__separator::before {
    position: absolute;
    background-color: #f9f7f2;z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    width: 100%;
    height: 10px;
}
