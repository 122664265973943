.popup-frm__btn {
    background-color: transparent;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    padding: 0;
    border: none;
    cursor: pointer;
    margin-bottom: 30px;
    transition: all 0.2s;
}

.popup-frm__btn:hover {
    color: #24d08b;
}